export const EReportTypes = {
  SalesRep: "SALES-REP",
  SupplierSpend: "SUPPLIER-SPEND",
  RepComparative: "REP-COMPARATIVE",
  SalesDashboard: "SALES-DASHBOARD",
  SalesPipeline: "SALES-PIPELINE",
  SalesActivity: "SALES-ACTIVITY",
  DashboardOpportunity: "DASHBOARD-OPPORTUNITY",
  DashboardPresentation: "DASHBOARD-PRESENTATION",
  DashboardEstimate: "DASHBOARD-ESTIMATE",
  DashboardSalesOrder: "DASHBOARD-SALES-ORDER",
  DashboardInProduction: "DASHBOARD-IN-PRODUCTION",
  DashboardInvoice: "DASHBOARD-INVOICE",
  DashboardInvoiceY1: "DASHBOARD-INVOICE-Y1",
  DashboardInvoiceY2: "DASHBOARD-INVOICE-Y2",
  DashboardInvoiceY3: "DASHBOARD-INVOICE-Y3",
  DashboardInvoiceY4: "DASHBOARD-INVOICE-Y4",
  DashboardPurchaseOrder: "DASHBOARD-PURCHASE-ORDER",
  DashboardAccount: "DASHBOARD-ACCOUNT",
  DashboardNote: "DASHBOARD-NOTE",
  DashboardCall: "DASHBOARD-CALL",
  DashboardMeeting: "DASHBOARD-MEETING",
  Commission: "COMMISSION",
  CommissionBill: "COMMISSION-BILL",
  CommissionInvoice: "COMMISSION-INVOICE",
  SalesTarget: "SALES-TARGET",
  MailingList: "MAILING-LIST",
  ProductsSold: "PRODUCTS-SOLD",
  ProductsSoldProject: "PRODUCTS-SOLD-PROJECT",
  ClientProject: "CLIENT-PROJECT",
  ContactImportHubspotContact: "CONTACT-IMPORT-HUBSPOT-CONTACT",
  FinanceReceiveBills: "FINANCE-RECEIVE-BILLS",
  FinanceExportBills: "FINANCE-EXPORT-BILLS",
  FinanceExportedBills: "FINANCE-EXPORTED-BILLS",
  FinanceSalesOrders: "FINANCE-SALES-ORDERS",
  FinanceDepositInvoices: "FINANCE-DEPOSIT-INVOICES",
  FinanceInvoices: "FINANCE-INVOICES",
  FinanceExportedInvoices: "FINANCE-EXPORTED-INVOICES",
  FinanceCreditMemos: "FINANCE-CREDIT-MEMOS",
  ClientComparative: "CLIENT-COMPARATIVE",
  FinanceVendorCredits: "FINANCE-VENDOR-CREDITS",
} as const;

interface ReportType {
  name: (typeof EReportTypes)[keyof typeof EReportTypes];
  title: string;
  path: string;
  v1Url?: string;
  description?: string;
  iconPath?: string;
  new?: boolean;
  capabilities?: string[];
  userFlags?: string[];
}

type ReportTypeKeys = keyof typeof EReportTypes;
export type ReportTypeValues = (typeof EReportTypes)[ReportTypeKeys];

export type ReportTypes = Partial<Record<ReportTypeValues, ReportType>>;

export const REPORT_TYPES: ReportTypes = {
  [EReportTypes.SalesRep]: {
    name: EReportTypes.SalesRep,
    title: "Sales Rep",
    path: "/report/sales-rep",
    description:
      "Track presentations, estimates, sales orders, and invoices by stage. Using search filters, look up forms by client, tag, status, contact, or address. Summary box shows total sales, average dollar amount, and average margin.",
    iconPath: "sales-rep.png",
    new: false,
  },
  [EReportTypes.SupplierSpend]: {
    name: EReportTypes.SupplierSpend,
    title: "Supplier Spend",
    path: "/report/supplier-spend",
    description:
      "Track how much you have created in POs for each vendor. With the ability to view this comparative report by month, quarter or year, you can track your supplier spend to see which vendors are trending upwards or downwards with your team.",
    iconPath: "supplierSpend.png",
    new: false,
    capabilities: ['HAS-SUPPLIER-SPEND-REPORT'],
  },
  [EReportTypes.RepComparative]: {
    name: EReportTypes.RepComparative,
    title: "Rep Comparative",
    path: "/report/rep-comparative",
    description:
      "Allows you to look at invoiced dollars by client or order rep and compare it to the previous month, quarter or year. Results will be presented in both a percentage and dollar value change for the time frame you set.",
    iconPath: "sales-dashboard.png",
    new: true,
    capabilities: ['HAS-REP-COMPARATIVE-REPORT'],
  },
  [EReportTypes.SalesDashboard]: {
    name: EReportTypes.SalesDashboard,
    title: "Sales Dashboard",
    path: "/report/sales-dashboard",
    description:
      "An overview of sales performance for yourself or your team. Assess the total number and dollar amount of sales orders created, purchased orders created, booked orders (in production) and invoices generated.",
    iconPath: "sales-dashboard.png",
    new: false,
  },
  [EReportTypes.SalesPipeline]: {
    name: EReportTypes.SalesPipeline,
    title: "Sales Pipeline",
    path: "/report/sales-pipeline",
    description:
      "An overview of pre-sales performance for yourself or your team. Assess the total number and budget amount of opportunties, presentations, and estimates created.",
    iconPath: "sales-dashboard.png",
    new: false,
  },
  [EReportTypes.SalesActivity]: {
    name: EReportTypes.SalesActivity,
    title: "Sales Activity",
    path: "/report/sales-activity",
    description:
      "An overview of sales activity for yourself or your team. Assess the total number opportunities, presentations, estimates, notes, calls and meetings created.",
    iconPath: "sales-dashboard.png",
    new: false,
  },
  [EReportTypes.Commission]: {
    name: EReportTypes.Commission,
    title: "Commissions",
    path: "/report/commission",
    description:
      "See commissions for yourself or pull a commission report for the company (permission dependent). See what commissions are both estimated and owed to each rep. Filter by paid/unpaid invoices, entered/not entered bills and paid/outstanding commissions by sales rep or by client account.",
    iconPath: "commissions.png",
    new: false,
    capabilities: ['HAS-COMMISSIONS'],
  },
  [EReportTypes.SalesTarget]: {
    name: EReportTypes.SalesTarget,
    title: "Sales Target",
    path: "/report/sales-target",
    description:
      "See year over year sales for your clients. Compare the current year sales against the annual target you have set for each client on the client page. Can be filtered by rep, client tag or client status. See the last activity or interaction you had with every client.",
    iconPath: "sales-target.png",
    new: false,
  },
  [EReportTypes.MailingList]: {
    name: EReportTypes.MailingList,
    title: "Mailing List",
    path: "/report/mailing-list",
    description:
      "Allows you to pull a list of contacts you have inside of commonsku. Will show any address associated with the contact and can be filtered by client tag, contact tag, address and department. Use this to export out a list of all contacts, emails and addresses.",
    iconPath: "mailing-list.png",
    new: false,
  },
  [EReportTypes.ProductsSold]: {
    name: EReportTypes.ProductsSold,
    title: "Products Sold",
    path: "/report/products-sold",
    description:
      "Gain insights into which products are selling the most and least frequently, and identify which ones generate the highest revenue. Easily filter by dates, suppliers, clients, industry, and tags, and view detailed data with sortable columns, product images, and direct links to project lists for in-depth analysis.",
    iconPath: "sales-dashboard.png",
    new: true,
    capabilities: ['HAS-PRODUCTS-SOLD-REPORT'],
  },
  [EReportTypes.ClientComparative]: {
    name: EReportTypes.ClientComparative,
    title: "Client Comparative",
    path: "/report/client-comparative",
    description:
      "Allows you to look at invoiced dollars by client and compare it to the previous month, quarter or year. Results will be presented in both a percentage and dollar value change for the time frame you set.",
    iconPath: "sales-dashboard.png",
    new: true,
    capabilities: ['HAS-CLIENT-COMPARATIVE-REPORT'],
  },
};
